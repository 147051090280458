* {
    box-sizing: border-box;
}

body, #root {
  font-family: sans-serif;
  margin: 0px;
  min-height: 100vh;
  padding: 0;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

#viewer {
  height: 100vh;
  width: 100vw;
}

#viewerDiv {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}